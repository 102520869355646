.student-profile-details-table {
	max-width: 90%;
	min-width: 35%;
	border-spacing: 0;
	empty-cells: show;
	text-align: left;
	margin: 0 5px 20px 5px;
	border: 1px solid darkgray;
}

.student-profile-details-table tr:nth-child(2n) {
	background-color: lavender;
}

.student-profile-details-table td {
	padding: 10px;
}

.cycle-nav-container {
	width: 100% - 10px;
	margin: 5px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas:
	"left . . right";
}

.survey_button {
	float: right;
  }

#cycle-back {
	grid-area: left;
}

#cycle-forward {
	grid-area: right;
}

#cycle-new {
	display: block;
	margin-top: 5px;
}

.surveyTable tr,.surveyTable td{
	border: 1px solid black;
}

.student-profile-header {
	color: #006dbe;
	padding: 10px 0 5px;
}
