.studentDetailsForm {
	text-align: left;
}

.studentDetailsForm div {
	margin-bottom: 10px;
	vertical-align: top;
}

.studentDetailsForm div span.studentField {
	display: inline-block;
	padding-inline: 10px;
	height: 40px;
	margin-bottom: 30px;
}

.student-details-name {
	color: #006dbe;
	padding: 10px 0 5px;
}

.student-details-section {
	line-height: 200%;;
}

.student-details-section .studentField {
	vertical-align: top;
	padding-top: 10px;
	padding-bottom: 60px;
}

.student-details-section h2 {
	display: block;
	border-top: 1px solid #006dbe;
	border-bottom: 1px solid #006dbe;
	color: #006dbe;
}
