.app-header-grid-container {
	display: grid;
	grid-template-columns: 100px 1fr 300px 400px;
	grid-template-rows: 80px 60px;
	gap: 0px 0px;
	grid-template-areas:
		"application-logo application-name user-name-text role-switch-controls"
		"nav-bar nav-bar nav-bar nav-bar";
	background-color: #fff;
}

.application-logo {
	grid-area: application-logo;
	padding: 5px;
}

.application-logo > img {
	width: 100px;
}

.application-name {
	grid-area: application-name;
	align-self: center;
	font-weight: bolder;
	font-size: 1.25em;
}

.role-switch-controls {
	grid-area: role-switch-controls;
	color: #5b828f;
	font-size: .85em;
	text-align: right;
	padding-top: 5px;
	padding-right: 1em;
}

.user-name-text {
	grid-area: user-name-text;
	color: #5b828f;
	font-style: italic;
	font-size: .85em;
	text-align: right;
	padding-top: 5px;
	padding-right: 1em;
}
.nav-bar {
	grid-area: nav-bar;
	background: #1560b7;
	padding-top: 5px;
	align-self: baseline;
}

.nav-bar ul li.header-menu-item {
	height: 3em;
	width: 8%;
	margin-left: 1em;
	text-align: center;
	font-weight: bold;
}

.nav-bar ul li.header-menu-item span {
	color: #fff;
}

.nav-bar ul li.header-menu-item span {
	justify-content: center;
}

.selected-menu-option {
	border-width: 2px !important;
	border-style: solid;
	border-color: #3c669c;
	border-radius: 10px 10px 0 0;
	background: #fff !important;
	color: #000 !important;
	border-bottom: none;
}

.selected-menu-option span {
	color: #000 !important;
}
