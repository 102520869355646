html {
  height: 100vh;
}

.diagram-component {
  z-index: 1;
  width: 100vw;
  min-height: 80vh;
  /* border: solid 2px black; */
  background: radial-gradient(white 60%, rgb(191, 191, 191));
  left: -12px;
  -webkit-tap-highlight-color: transparent;
}

.canvasStyle {
  position: absolute; 
  top: 0px; 
  left: 0px; 
  z-index: 2; 
  user-select: none; 
  touch-action: none; 
  width: 1054px; height: 398px;
}

.projectTitleBox {
  background-color: rgb(21,96,183);
  left: 0px;
  z-index: 99;
  position: absolute;
  /* display: sticky; */
  padding-top: 0.2rem;
  padding-bottom: 0.5rem;
  width: 100vw;
}

.projectHeader {
  margin-left: 15px;
  font-size: 32px;
  color: white;
  font-weight:400;
}

/* VIEW OPTIONS MENU */

.viewMenu {
  z-index:99;
  right: 0;
  background-color: white;
  margin-top: 100px;
  margin-right: 50px;
  position: fixed;
  
  border-radius: 8px;
  background-color: white;
  filter: drop-shadow(3px 3px 5px);
  
}

#viewMenuDragArrows {
  position: absolute;
  margin-left: 5px;
  margin-top: 5px;
  text-align: left;
}

.viewMenuHeader {
  font-weight: 600;
  font-size: 14px;
  color: rgb(21,96,183);
  margin-top: 5px;
  text-align: center;
}

.viewMenuTools {
  padding: 5px 8px 8px 8px;
  text-align: center;
  /* filter: drop-shadow(0px 0px 0.75px); */
}

.layeringOptionButton {
  height: 40px;
  width: 40px;
  font-size: 24px;
  color: white;
  background-color: rgb(21,96,183);
  border-radius: 8px;
  outline: none;
  border: none;
  padding: 1px;
  margin: 2px;
  filter: drop-shadow(0px 0px 1px);
}

.rotateButton {
  height: 40px;
  width: 40px;
  font-size: 24px;
  color: white;
  background-color: rgb(21,96,183);
  border-radius: 8px;
  outline: none;
  border: none;
  margin: 4px;
  filter: drop-shadow(0px 0px 1px);
}

#LayerLongestPathSource {
  transform: rotate(90deg);
}

#LayerLongestPathSink {
  transform: rotate(-90deg);
}

p {
  font: 300 1rem Roboto, helvetica, sans-serif;
  color: #212121; /* Grey 900 */
}

/* Toolbar */

.toolbar {
  z-index: 99;
  position: absolute;
  display: sticky;
  align-items:center;
  justify-content: center;
  margin: 75px 50px;
}

.toolButton {
  cursor: pointer;
  padding: 15px;
  color: white;
  background-color: rgb(21,96,183);
  border-top: 1px solid white;
}

.toolButtonBlue {
  cursor: pointer;
  padding: 15px;
  color: white;
  background-color: rgb(21,96,183);
  border-top: 1px solid white;
}

.toolButtonGrey {
  cursor: pointer;
  padding: 15px;
  color: black;
  background-color: rgb(white);
  border-top: 1px solid white;
}