.rosters-container {
	padding: 10px;
}

.rosters-container > .roster-button-container {
	margin: 10px 0;
}

.roster-button {
	text-align: right;
}

.rosters-container > .breadcrumb, .rosters-container > .breadcrumb::before, .rosters-container > .breadcrumb::after {
	color: #006dbe;
	border: none;
	background: none;
	box-shadow: none;
	text-transform: none;
	transition: none;
	text-decoration: underline;
}

.rosters-container > .k-button {
	margin-bottom: 10px;
}

.roster-teacher-header {
	color: #006dbe;
}

.roster-name-header {
	color: #006dbe;
}

.roster-name {
	text-decoration: underline;
	cursor: pointer;
}

.student-linked-name {
	cursor: pointer;
	/** overwriting Kendo Ui anchor tag styling **/
	color: blue !important;
	text-decoration: underline !important;
}