#filternotifications h1,
#filternotifications h2 {
	color: #3b7799;
}

#filternotificationsContent {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  gap: 0px 0px;
  grid-template-areas:
    ". .";
	padding-left: 10px;
	padding-right: 10px;
	height: 100%;
}

#filternotifications #filters {
	width: 100%
}

#filternotifications #filters h2 {
    margin-bottom: 10px;
}

#filternotifications #notifications {
	width: 90%;
}
