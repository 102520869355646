body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.p-5 {
	padding: 5px;
}

.p-10 {
	padding: 10px;
}

.p-h-10 {
	padding: 10px 0;
}

.p-v-10 {
	padding: 0 10px;
}

body .cke_float {
	width: 600px;
}

.m-5 {
	margin: 5px;
}

.m-10 {
	margin: 10px;
}

input.k-radio, input.k-radio:hover {
	color: #000;
	border: 2px solid;
}

input.k-radio:checked, input.k-radio:checked:hover {
	color: #1560b7;
}

input.k-checkbox, input.k-checkbox:focus, input.k-checkbox:hover {
	color: #000;
	border: 2px solid;
}

input.k-checkbox:checked {
	border-color: #1560b7;
	color: #ffffff;
	background-color: #1560b7;
}

.requiredField::after {
	content: "*";
	color: #B30000;
}

.notRequiredField {
	padding-left: 6px;
}

h4 {
	color: #1560b7; 
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; 
	font-size: 16px; 
	font-weight: 600;
}

h5 {
	color: #000; 
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; 
	font-size: 16px; 
}

label {
	color: #000;
}

.multi-select-border .k-multiselect-wrap {
	border-color: #000 !important;
}

.Login input[type=email], .Login input[type=password] {
	border-top-color: grey !important
}

.k-button {
	text-transform: capitalize;
}

.userProfile{
	margin-top: 50px;
	height: 430px;
	border: 1px solid #ccc;
	margin-right: 15px;
}

/* .navbar {
	background-color: #1560b7;
} */

.kite_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 999;

}

.gsfBackground {
    border-color: #b9c6fb;
    font-size: 10px;
    margin-bottom: 7px;
}

.alignLeft {
	float: left;
}

.alignRight {
	float: right;
}


#roster-back-button {
	margin-top: 1rem;
}

.page-description-container {
	padding: 0.5rem;
	margin-top: 15px;
}

.page-description-paragraph {
	padding: 0.5rem;
}

#student-roster-description-icon {
	margin-right: 1rem;
	margin-left: 1rem;
	left: 0;
	font-size: 18px;
};

.student-maps-container {
	position: relative;
}

.student-map-button-instructions {
	padding: 0.5rem;
	margin: 0.5rem;
}