#home {
	width: 100%;
	height: 100vh;
	background-image: url("./Kite_B_2x.png");
	background-size: 100%;
	background-repeat: no-repeat;
	background-attachment: scroll;
	overflow-y: auto;
	margin-top: 25px;
}

#home h1,
#home h2 {
	color: #3b7799;
}

#homeContent {
	display: flex;
	align-items: flex-start;
	flex-flow: column wrap;
	flex-direction: row;
	justify-content: space-evenly;
}

#home #news {
	align-self: auto;
}

#home #news h2 {
    margin-bottom: 0;
}

#home #news p.content {
    margin-top: 0;
}

#home #notifications {
	align-self: auto;
}
